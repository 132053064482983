import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { LockupHorisontallyLogo } from '../../logos';
import type { Props } from './types';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface BrandProps extends Props {
  logo?: React.ReactElement;
}

export const Brand: React.FC<BrandProps> = ({
  link,
  logo = <LockupHorisontallyLogo />,
}) => (
  <Wrapper>
    {link ? (
      <Link
        to={link}
        css={css`
          display: flex;
        `}
      >
        {logo}
      </Link>
    ) : (
      logo
    )}
  </Wrapper>
);
