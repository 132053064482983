import { css } from '@emotion/react';
import { TextButton, mq } from 'folio-common-components';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import { useFirstPaintNoSessionData } from '../../hooks/use-first-paint-data';

export const LogOutLink: React.FC = () => {
  const isWide = useMediaLayout(mq.medium);
  const { data: noSessionData } = useFirstPaintNoSessionData();

  const isLoggedIn = noSessionData?.simpleUserInfo.isLoggedIn ?? false;
  const name = noSessionData?.simpleUserInfo.structuredName?.full ?? null;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      {isWide ? name || <PlaceHolder /> : null}
      {isLoggedIn ? (
        <form method="post" action="/logg-ut">
          <TextButton type="submit">Logg ut</TextButton>
        </form>
      ) : (
        <PlaceHolder />
      )}
    </div>
  );
};

const PlaceHolder: React.FC = () => <span>{'\xA0'}</span>;
