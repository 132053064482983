import * as React from 'react';
import { createContainer } from 'unstated-next';

export type Kind = 'info' | 'success' | 'error';

export interface AppNotification {
  readonly id: string;
  readonly title?: React.ReactNode;
  readonly body: React.ReactNode;
  readonly timeStamp: number;
  readonly dismissed: boolean;
  readonly kind: Kind;
  readonly onPress: () => void;
}

interface State {
  notifications: readonly AppNotification[];
}

type AppNotificationOptions = Pick<AppNotification, 'title' | 'body'> & {
  readonly id?: string;
  readonly kind?: Kind;
  readonly onPress?: () => void;
};

type Action =
  | { type: 'add'; value: AppNotificationOptions }
  | { type: 'dismiss'; id: string };

export interface NotificationContext {
  notifications: readonly AppNotification[];
  unread: readonly AppNotification[];
  show: (options: AppNotificationOptions) => void;
  dismiss: (id: string) => void;
}

const noop = () => {
  // empty
};

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'add': {
      const id = action.value.id || String(Math.random());
      const newNotification: AppNotification = {
        title: action.value.title,
        body: action.value.body,
        kind: action.value.kind || 'info',
        id,
        timeStamp: Date.now(),
        dismissed: false,
        onPress: action.value.onPress || noop,
      };
      // Remove any notification with the same id, effectively replacing it
      const notifications = state.notifications.filter(e => e.id !== id);
      return {
        ...state,
        notifications: [newNotification, ...notifications].slice(0, 100),
      };
    }

    case 'dismiss': {
      const notifications = state.notifications.map(e =>
        e.id === action.id ? { ...e, dismissed: true } : e,
      );
      return {
        ...state,
        notifications,
      };
    }
  }
};

function useNotifications(): NotificationContext {
  const [state, dispatch] = React.useReducer(reducer, { notifications: [] });
  /**
   * Show a notification. If the `id` matches a currently shown notification, it
   * will be replaced.
   */
  const show = React.useCallback(
    (value: AppNotificationOptions) => dispatch({ type: 'add', value }),
    [],
  );
  const dismiss = React.useCallback(
    (id: string) => dispatch({ type: 'dismiss', id }),
    [],
  );
  const { notifications } = state;

  return React.useMemo(() => {
    const unread = notifications.filter(e => !e.dismissed);

    return { notifications, unread, show, dismiss };
  }, [dismiss, notifications, show]);
}

const { Provider, useContainer } = createContainer(useNotifications);

export { Provider as NotificationsProvider, useContainer as useNotifications };

export function useAutodismissingNotification(
  notification: Pick<AppNotification, 'id' | 'kind'>,
) {
  const { dismiss } = useContainer();

  const { id, kind } = notification;

  const dismissTimeoutId = React.useRef<ReturnType<typeof setTimeout>>();
  const dismissNotification = React.useCallback(
    () => dismiss(id),
    [dismiss, id],
  );
  const resetTimeout = React.useCallback(() => {
    if (dismissTimeoutId.current) {
      clearTimeout(dismissTimeoutId.current);
    }
  }, []);

  const hideAfterTimeout = React.useCallback(() => {
    resetTimeout();

    if (kind !== 'error') {
      dismissTimeoutId.current = setTimeout(dismissNotification, 5000);
    }
  }, [dismissNotification, kind, resetTimeout]);

  React.useEffect(() => hideAfterTimeout(), [hideAfterTimeout]);

  return {
    dismiss: dismissNotification,
    resetTimeout,
    hideAfterTimeout,
  };
}
