import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { expandEvent, flipExpandedEvent } from 'bank-common-client';
import {
  ProgressSpinner,
  Strikethrough,
  fonts,
  space,
} from 'folio-common-components';
import { formatters, listSum, partition } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload';
import { useMediaLayout } from 'use-media';
import { underlineOnHoverStyle } from '../../components/LinkButton';
import { ColoredLabel } from '../../components/colored-label';
import {
  dropTargetOverStyle,
  dropTargetStyle,
} from '../../components/drop-target-style';
import {
  Divider,
  EventContentWrapper,
} from '../../components/event-content/shared';
import { useOrgPathAbsoluteUrl } from '../../components/org-navigation';
import { getCustomLedgerTitle } from '../../components/payment-helpers';
import { getEventCompletionStatus } from '../../components/shared/bookkeeping';
import type { PaymentStatus } from '../../gqltypes';
import { DragAndDropProvider } from '../../hooks/drag-and-drop-context';
import { useHasQueryArg } from '../../hooks/use-query-arg';
import { useReceiptDropTarget } from '../../hooks/use-receipt-drop-target';
import { Download2Icon, PercentIcon, PersonDollarIcon } from '../../icons';
import { pages } from '../../pages';
import { touchFeedback } from '../../styles/touch-feedback';
import { isInternalTransfer } from '../../utils/movement-types';
import { EventSummaryRow } from './EventSummaryRow';
import { TransactionWrapper } from './TransactionCard';
import type { EventItem, SalaryPaymentItem } from './types';

type BulkPayments = SalaryPaymentItem['payments'];

const EventContent = lazyWithPreload(
  () => import('../../components/event-content'),
);

EventContent.preload();

const expandableEventSummaryRowStyle = css`
  cursor: pointer;
  border-radius: 8px;

  &:focus {
    box-shadow: none;
  }

  @media (hover) {
    &:hover {
      box-shadow: none;
    }
  }

  ${dropTargetStyle};

  &:focus::after {
    border: 2px solid ${colors.blue};
  }

  @media (hover) {
    &:hover::after {
      border: 2px solid ${colors.blue};
    }
  }

  ${touchFeedback};
`;

const ExpandableEventSummaryRow: React.FC<
  React.PropsWithChildren<{ expanded: boolean; onToggle: () => void }>
> = ({ expanded, onToggle, ...rest }) => {
  return (
    <div
      // This is not a real button because the text must be selectable.
      // It seems there is no way to do that in Safari, even with `user-select: text`.
      role="button"
      tabIndex={0}
      onKeyDown={event => {
        const { key } = event;
        if (key === ' ' || key === 'Enter') {
          event.preventDefault();
          onToggle();
        }
      }}
      onClick={event => {
        const { currentTarget } = event;
        const selection = window.getSelection();
        const selectionIsInButton =
          selection?.focusNode && currentTarget.contains(selection.focusNode);
        // Checking if the selection is in the button might not be necessary
        // since selection should be cleared on clicking somewhere anyway, but
        // better safe than sorry.
        if (selectionIsInButton && !selection.isCollapsed) {
          // The user has selected something, don't expand the button
          return;
        }
        onToggle();
      }}
      onMouseDown={event => {
        // Prevent text selection on double-click
        if (event.detail > 1) {
          event.preventDefault();
        }
      }}
      aria-expanded={expanded}
      css={expandableEventSummaryRowStyle}
      {...rest}
    />
  );
};

const DragAndDropWrapper: React.FC<
  React.PropsWithChildren<{
    eventFid: string;
    setIsDraggedOver: (value: boolean) => void;
  }>
> = ({ eventFid, setIsDraggedOver, children }) => {
  const { eventListeners, isDraggedOver } = useReceiptDropTarget({
    eventFid,
    handleDrop() {
      expandEvent(eventFid);
    },
  });

  React.useEffect(() => {
    setIsDraggedOver(isDraggedOver);
  }, [isDraggedOver, setIsDraggedOver]);

  return (
    <TransactionWrapper {...eventListeners}>{children}</TransactionWrapper>
  );
};

const MaybeDragAndDropWrapper: React.FC<
  React.PropsWithChildren<{
    eventFid: string;
    editable: boolean;
    internalTransfer: boolean;
    setIsDraggedOver: (value: boolean) => void;
  }>
> = ({ eventFid, editable, internalTransfer, setIsDraggedOver, children }) => (
  // we always need the provider (but not the listeners) since we check for an upload further down
  <DragAndDropProvider>
    {editable && !internalTransfer ? (
      <DragAndDropWrapper
        eventFid={eventFid}
        setIsDraggedOver={setIsDraggedOver}
      >
        {children}
      </DragAndDropWrapper>
    ) : (
      <TransactionWrapper>{children}</TransactionWrapper>
    )}
  </DragAndDropProvider>
);

const BaseTransactionRow: React.FC<{
  event: EventItem;
  expanded: boolean;
  backlink?: string;
}> = ({ event, expanded, backlink }) => {
  const {
    amount,
    currencyAmount,
    currencyCode,
    fid,
    ledgerCategoryInfo,
    movementType,
    isEditable,
    payment,
    infoLine,
    transaction,
  } = event;
  const hasEditQuery = useHasQueryArg('edit');
  const [isDraggedOver, setIsDraggedOver] = React.useState(false);

  const status = getEventCompletionStatus(event);
  const editable = hasEditQuery || isEditable;

  let description = payment?.external
    ? getCustomLedgerTitle(ledgerCategoryInfo)
    : null;

  if (description == null) {
    description = event.description;
  }

  const internalTransfer = isInternalTransfer(movementType);

  const toggle = React.useCallback(() => {
    flipExpandedEvent(fid);
    pages.singleTransaction.loadableComponent.preload();
  }, [fid]);

  const pendingTransaction = transaction?.isPending;

  const eventRow = (
    <EventSummaryRow
      amount={Number(amount)}
      currencyAmount={currencyAmount ? Number(currencyAmount) : null}
      currencyCode={currencyCode}
      paymentFee={payment?.fee}
      expanded={expanded}
      description={description}
      movementType={movementType}
      category={ledgerCategoryInfo.category}
      progressStatus={status}
      name={infoLine ?? undefined}
      accountingSystemInformation={payment?.accountingSystemInformation ?? null}
      pendingTransaction={pendingTransaction}
    />
  );

  return (
    <MaybeDragAndDropWrapper
      eventFid={fid}
      editable={editable}
      setIsDraggedOver={setIsDraggedOver}
      internalTransfer={internalTransfer}
    >
      {pendingTransaction ? (
        eventRow
      ) : (
        <ExpandableEventSummaryRow
          css={isDraggedOver ? dropTargetOverStyle : undefined}
          expanded={expanded}
          onToggle={toggle}
        >
          {eventRow}
        </ExpandableEventSummaryRow>
      )}
      {expanded ? (
        <React.Suspense
          fallback={
            <div
              css={css`
                display: flex;
                justify-content: center;
                ${space([24], 'padding-bottom')};
              `}
            >
              <ProgressSpinner size={48} />
            </div>
          }
        >
          <EventContent
            event={event}
            isSingleTransaction={false}
            editable={editable}
            backlink={backlink}
            isInternalTransfer={internalTransfer}
          />
        </React.Suspense>
      ) : null}
    </MaybeDragAndDropWrapper>
  );
};

// need to memoize, otherwise expanding a row takes ~300ms (due to rendering all rows)
export const TransactionRow = React.memo(BaseTransactionRow);

export const SalaryPaymentTransactionRow: React.FC<{
  bulkEvent: SalaryPaymentItem;
  expanded: boolean;
}> = ({ bulkEvent, expanded }) => {
  const completedAmount = -listSum(
    bulkEvent.payments
      .filter(e => e.status === 'PaymentStatusCompleted')
      .map(e => e.nokAmount.asAbsoluteNumber),
  );
  return (
    <TransactionWrapper>
      <ExpandableEventSummaryRow
        onToggle={() => {
          flipExpandedEvent(bulkEvent.fid);
        }}
        expanded={expanded}
      >
        <EventSummaryRow
          amount={completedAmount}
          expanded={expanded}
          description="Lønn og forskuddstrekk"
          movementType="Send"
          category={bulkEvent.ledgerCategoryInfo.category}
          progressStatus="complete"
          name={bulkEvent.debtorAccount.name}
          accountingSystemInformation={null}
        />
      </ExpandableEventSummaryRow>
      {expanded ? <BulkEventContent event={bulkEvent} /> : null}
    </TransactionWrapper>
  );
};

const BulkEventContent: React.FC<{ event: SalaryPaymentItem }> = ({
  event,
}) => {
  const isWide = useMediaLayout('(min-width: 600px');
  const [taxPayments, salaryPayments] = partition(
    event.payments,
    payment => payment.isTaxPayment,
  );

  const urlWithOrgPath = useOrgPathAbsoluteUrl();
  const confirmationUrl = urlWithOrgPath(
    `betalingsbekreftelse-pakke/${event.fid}`,
  );

  return (
    <EventContentWrapper>
      <Divider />

      <div
        css={css`
          display: grid;
          ${space([32], 'gap')};
        `}
      >
        {salaryPayments.length > 0 ? (
          <div>
            <Heading>Lønn</Heading>
            {isWide ? (
              <WideSalaryGrid salaryPayments={salaryPayments} />
            ) : (
              <NarrowSalaryGrid salaryPayments={salaryPayments} />
            )}
          </div>
        ) : null}

        {taxPayments.length > 0 ? (
          <div>
            <Heading>Forskuddstrekk</Heading>
            {isWide ? (
              <WideTaxGrid taxPayments={taxPayments} />
            ) : (
              <NarrowTaxGrid taxPayments={taxPayments} />
            )}
          </div>
        ) : null}
      </div>

      <Divider />

      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <a
          css={css`
            display: flex;
            color: inherit;
            ${underlineOnHoverStyle};
          `}
          href={confirmationUrl}
          download={true}
        >
          <Download2Icon css={space([8], 'margin-right')} />
          Betalingsbekreftelse
        </a>
      </div>
    </EventContentWrapper>
  );
};

const SalaryPaymentLabel: React.FC<{ status: PaymentStatus }> = props => {
  switch (props.status) {
    case 'PaymentStatusCancelled':
      return <ColoredLabel color="blue">Avbrutt</ColoredLabel>;
    case 'PaymentStatusInsufficientFunds':
    case 'PaymentStatusRejected':
      return <ColoredLabel color="red">Ikke betalt</ColoredLabel>;
    default:
      return null;
  }
};

const WideSalaryGrid: React.FC<{
  salaryPayments: BulkPayments;
}> = ({ salaryPayments }) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: 48px auto 1fr auto; /* 48px aligns with the category icon */
      ${space([16], 'gap', 'margin-top')};
      align-items: center;
    `}
  >
    {salaryPayments.map(payment => {
      const shouldBeGrayedOut = payment.status !== 'PaymentStatusCompleted';
      const formattedAmount = formattedAbsoluteAmount(payment.nokAmount);

      return (
        <React.Fragment key={payment.paymentFid}>
          <div
            css={css`
              justify-self: center;
            `}
          >
            <PersonDollarIcon
              css={css`
                display: block;
                color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
              `}
            />
          </div>
          <div
            css={css`
              color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
            `}
          >
            {formatters.formatAccountNumber(payment.creditorAccount)}
          </div>
          <div
            css={css`
              overflow: hidden;
            `}
          >
            <div
              css={css`
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
              `}
            >
              {payment.creditorName}{' '}
              <SalaryPaymentLabel status={payment.status} />
            </div>
          </div>
          {shouldBeGrayedOut ? (
            <Strikethrough
              css={css`
                color: var(--muted-color);
              `}
            >
              {formattedAmount}
            </Strikethrough>
          ) : (
            formattedAmount
          )}
        </React.Fragment>
      );
    })}
  </div>
);

const NarrowSalaryGrid: React.FC<{
  salaryPayments: BulkPayments;
}> = ({ salaryPayments }) => (
  <div
    css={css`
      display: grid;
      ${space([16], 'gap', 'margin-top')};
    `}
  >
    {salaryPayments.map(payment => {
      const shouldBeGrayedOut = payment.status !== 'PaymentStatusCompleted';
      const formattedAmount = formattedAbsoluteAmount(payment.nokAmount);
      return (
        <div key={payment.paymentFid}>
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr auto;
              ${space([16], 'column-gap')};
            `}
          >
            <div
              css={css`
                color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
              `}
            >
              {formatters.formatAccountNumber(payment.creditorAccount)}
            </div>
            {shouldBeGrayedOut ? (
              <Strikethrough
                css={css`
                  color: var(--muted-color);
                `}
              >
                {formattedAmount}
              </Strikethrough>
            ) : (
              formattedAmount
            )}
            <div
              css={css`
                color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
              `}
            >
              {payment.creditorName}{' '}
              <SalaryPaymentLabel status={payment.status} />
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

const WideTaxGrid: React.FC<{
  taxPayments: BulkPayments;
}> = ({ taxPayments }) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: 48px 1fr auto; /* 48px aligns with the category icon */
      ${space([16], 'gap', 'margin-top')};
      align-items: center;
    `}
  >
    {taxPayments.map(payment => {
      const shouldBeGrayedOut = payment.status !== 'PaymentStatusCompleted';
      const formattedAmount = formattedAbsoluteAmount(payment.nokAmount);
      return (
        <React.Fragment key={payment.paymentFid}>
          <div
            css={css`
              justify-self: center;
            `}
          >
            <PercentIcon
              css={css`
                display: block;
                color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
              `}
            />
          </div>
          <div
            css={css`
              overflow: hidden;
            `}
          >
            <div
              css={css`
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
              `}
            >
              Til skattetrekkskonto{' '}
              <SalaryPaymentLabel status={payment.status} />
            </div>
          </div>
          {shouldBeGrayedOut ? (
            <Strikethrough
              css={css`
                color: var(--muted-color);
              `}
            >
              {formattedAmount}
            </Strikethrough>
          ) : (
            formattedAmount
          )}
        </React.Fragment>
      );
    })}
  </div>
);

const NarrowTaxGrid: React.FC<{
  taxPayments: BulkPayments;
}> = ({ taxPayments }) => (
  <div
    css={css`
      display: grid;
      ${space([16], 'gap', 'margin-top')};
    `}
  >
    {taxPayments.map(payment => {
      const shouldBeGrayedOut = payment.status !== 'PaymentStatusCompleted';
      const formattedAmount = formattedAbsoluteAmount(payment.nokAmount);
      return (
        <div key={payment.paymentFid}>
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr auto;
              ${space([16], 'column-gap')};
            `}
          >
            <div
              css={css`
                color: ${shouldBeGrayedOut ? 'var(--muted-color)' : null};
              `}
            >
              Til skattetrekkskonto{' '}
              <SalaryPaymentLabel status={payment.status} />
            </div>
            {shouldBeGrayedOut ? (
              <Strikethrough
                css={css`
                  color: var(--muted-color);
                `}
              >
                {formattedAmount}
              </Strikethrough>
            ) : (
              formattedAmount
            )}
          </div>
        </div>
      );
    })}
  </div>
);

const Heading = styled.h4`
  ${fonts.font100book};
  color: var(--muted-color);
  margin: 0;
`;

function formattedAbsoluteAmount(amount: BulkPayments[number]['nokAmount']) {
  return formatters.formatAmount(amount.asAbsoluteNumber, {
    withoutFraction: false,
  });
}
