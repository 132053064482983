import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { Switch, fonts, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { StopAutofillDocument } from '../../common-client-queries.generated';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { LinkButton } from '../LinkButton';
import { SetAutofillDocument } from './queries.generated';
import { formatAmount } from './shared';
import type { AccountInfo, AutofillAccount } from './types';

export const AutofillDetailsBox = ({
  account,
}: {
  account: AutofillAccount;
}) => {
  const [showDetailsText, setShowDetailsText] = React.useState(false);
  const autofillId = React.useId();

  return (
    <div
      css={css`
        ${space([16], 'padding', 'margin-top')};
        background-color: ${colors.grayAiry};
        border-radius: 8px;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          ${space([4], 'gap')};
        `}
      >
        <div
          id={autofillId}
          css={css`
            ${fonts.font200medium};
          `}
        >
          Automatisk påfyll
        </div>

        <CardAutofillSwitch account={account} aria-labelledby={autofillId} />
      </div>
      {account.autofill ? (
        <div
          css={css`
            ${space([4], 'margin-bottom')};
            color: var(--muted-color);
          `}
        >
          Fyller opp til {formatAmount(Number(account.autofill.desiredAmount))}
        </div>
      ) : undefined}
      <div>
        {showDetailsText ? (
          <div css={space([16], 'margin-vertical')}>
            {account.autofill ? (
              <AutofillInformationText
                autofill={account.autofill}
                canSetAutofill={true}
              />
            ) : (
              <>
                Eksempel: Du har valgt at det skal stå {formatAmount(10_000)} på
                kortet. Når saldo på kort er under {formatAmount(5_000)}, fylles
                det automatisk opp til {formatAmount(10_000)} igjen.
                Overføringer til kort får en daglig grense på{' '}
                {formatAmount(10_000)}.
              </>
            )}
          </div>
        ) : null}
        <LinkButton
          onClick={() => setShowDetailsText(!showDetailsText)}
          data-testid="autofill-details-button"
        >
          {showDetailsText ? 'Lukk' : 'Forklaring'}
        </LinkButton>
      </div>
    </div>
  );
};

export const AutofillInformationText = ({
  autofill,
  canSetAutofill,
}: {
  autofill: NonNullable<AutofillAccount['autofill']>;
  canSetAutofill: boolean;
}) => {
  const lowLimit = Number(autofill.lowLimit);
  const desiredAmount = Number(autofill.desiredAmount);
  const dailyLimit = Number(autofill.dailyLimit);

  return (
    <>
      Overfører penger fra driftskonto sånn at du alltid har dekning på kortet.
      Når saldo på kort er under {formatAmount(lowLimit)}, fylles det automatisk
      opp til {formatAmount(desiredAmount)} igjen. Overføringer til kort har en
      daglig grense på {formatAmount(dailyLimit)}.
      {canSetAutofill ? ' Trenger du mer kan du overføre selv.' : null}
    </>
  );
};

const CardAutofillSwitch: React.FC<
  {
    account: AccountInfo | AutofillAccount;
  } & (
    | { children: React.ReactNode; ['aria-labelledby']?: never }
    | { children?: never; ['aria-labelledby']: string }
  )
> = ({ account, ...props }) => {
  const { data: firstPaintData } = useFirstPaintData();
  const [setAutofill, { loading: startLoading }] =
    useMutation(SetAutofillDocument);

  const [stopAutofillMutation, { loading: stopLoading }] = useMutation(
    StopAutofillDocument,
    { variables: { accountNumber: account.accountNumber } },
  );

  if (!firstPaintData) {
    return null;
  }

  const loading = startLoading || stopLoading;
  const { autofill, balanceNok, accountNumber } = account;
  const balance = balanceNok?.asNumber ?? 0;
  const isSelected = autofill ? !stopLoading : startLoading;

  return (
    <Switch
      isLoading={loading}
      isSelected={isSelected}
      onChange={() => {
        if (loading) {
          return;
        }

        if (autofill) {
          stopAutofillMutation();
        } else {
          setAutofill({
            variables: {
              desiredAmount: String(balance),
              accountNumber,
            },
          });
        }
      }}
      {...props}
    />
  );
};
