import type { TextInputProps } from 'folio-common-components';

export function createInputError(
  error: string | undefined,
): TextInputProps['message'] {
  if (error) {
    return { kind: 'error', content: error };
  }
  return undefined;
}
