import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider as BaseDivider, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { menuLinkStyle } from '../OverlayMenu';

export const EventContentWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <div
    css={css`
      display: grid;
      ${space([32], 'gap', 'padding-bottom')};
      ${space([16], 'padding-horizontal')};
    `}
  >
    {children}
  </div>
);

export const Divider = styled(BaseDivider)`
  margin: 0;
`;

export const StyledMenuLink = styled.a`
  position: relative;
  border-radius: 8px;
  ${menuLinkStyle};
  ${space([8], 'padding')};
  display: flex;
  -webkit-tap-highlight-color: transparent;

  @media (hover) {
    :hover {
      background: ${colors.grayAiryOpaque};
    }
  }

  :active {
    background: ${colors.grayAiryOpaque};
  }

  /* This makes sure there are no uncklickable areas between menu items, which looks
  better when moving the cursor between items since it avoids flashing the cursor. */
  ::before {
    content: '';
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: 0;
    right: 0;
  }
`;
