import { css } from '@emotion/react';
import { fonts } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import { useFirstPaintNoSessionData } from '../hooks/use-first-paint-data';
import { CleaningIllustration } from '../illustrations';
import { MinimalHeaderBar } from './MinimalHeaderBar';
import { ChatButton } from './chat-button';
import { InfoPage } from './info-page';
import { Intercom } from './intercom';

export function useDownForMaintenance():
  | { status: 'loading' }
  | { status: 'default' }
  | {
      status: 'down-for-maintenance';
      defaultScreen: () => React.ReactElement;
    } {
  const firstPaintNoSessionData = useFirstPaintNoSessionData();
  const broadcast = firstPaintNoSessionData.data?.broadcast.find(broadcast =>
    broadcast.scopes.includes('DownForMaintenance'),
  );

  if (firstPaintNoSessionData.loading) {
    return { status: 'loading' };
  }

  if (broadcast) {
    return {
      status: 'down-for-maintenance',
      defaultScreen() {
        return (
          <>
            <MinimalHeaderBar />
            <InfoPage
              illustration={<CleaningIllustration />}
              heading={broadcast.heading ?? 'Stengt for vedlikehold'}
              body={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: broadcast.message,
                    }}
                  />
                  <p>
                    <a href="https://folio.no/guider-og-artikler/folio-stenges-for-vedlikehold">
                      Les mer om nedetid
                    </a>
                  </p>
                  <div
                    css={css`
                      ${fonts.font200demi};
                      margin-top: 40px;
                    `}
                  >
                    Sperre kort?
                  </div>
                  Meld fra på <ChatButton color={colors.blue}>chat</ChatButton>{' '}
                  eller <a href="mailto:hei@folio.no">hei@folio.no</a>
                </>
              }
            />
            <Intercom />
          </>
        );
      },
    };
  }

  return { status: 'default' };
}
