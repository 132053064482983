import { useLazyQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OverlayContainer } from '@react-aria/overlays';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import {
  Button,
  Label,
  NumberValueInput,
  PlainButton,
  type TextInputProps,
  fonts,
  noFocus,
  space,
} from 'folio-common-components';
import { formatters, invariant } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { FirstPaintDocument } from '../../common-queries.generated';
import { XForCloseIcon } from '../../icons';
import { ApprovePaymentDocument } from '../../queries.generated';
import { BankIdSign } from '../../routes/payment/BankIdSign';
import { createInputError } from '../../utils/create-input-error';
import { ModalDialog, dialogHeadingStyle } from '../modal-dialog';
import { EmployeePayDocument, SetAutofillDocument } from './queries.generated';
import { formatAmount, getAmountToTransfer } from './shared';
import type { AccountType } from './types';

const narrowMq = '(min-width: 400px)';
const veryNarrowMq = '(min-width: 360px)';

export type { AccountsType, AccountType, Card } from './types';

type State = {
  newBalance: string;
  signingUrl: string | null;
  isLoading: boolean;
  cachedAmountToTransfer: number | undefined;
};

type Action =
  | {
      type: 'set-signing-url';
      signingUrl: string | null;
    }
  | { type: 'set-transfer-start'; cachedAmountToTransfer: number }
  | { type: 'set-transfer-succeeded' }
  | { type: 'set-transfer-failed' }
  | { type: 'set-new-balance'; balance: string };

// This is completely arbitrary.
const MAX_BALANCE = 999_999;
const maxBalanceLength = formatters.formatAmount(MAX_BALANCE).length;

const WARNING_AMOUNT = 50_000;

const reducer: React.Reducer<State, Action> = (prevState, action) => {
  switch (action.type) {
    case 'set-signing-url':
      return { ...prevState, signingUrl: action.signingUrl };

    case 'set-transfer-start': {
      return {
        ...prevState,
        isLoading: true,
        cachedAmountToTransfer: action.cachedAmountToTransfer,
      };
    }

    case 'set-transfer-succeeded': {
      return {
        ...prevState,
        signingUrl: null,
        newBalance: '',
        isLoading: false,
        cachedAmountToTransfer: undefined,
      };
    }

    case 'set-transfer-failed':
      return {
        ...prevState,
        signingUrl: null,
        isLoading: false,
        cachedAmountToTransfer: undefined,
      };

    case 'set-new-balance': {
      if (action.balance === '') {
        return { ...prevState, newBalance: '' };
      } else {
        const newBalanceAsNumber = Math.trunc(Number(action.balance));
        const newBalance = Math.min(newBalanceAsNumber, MAX_BALANCE);
        return {
          ...prevState,
          newBalance: String(newBalance),
        };
      }
    }
  }
};

export type OnPaymentStatus = (
  status: 'success' | 'failure',
  account: AccountType,
) => void;

export interface TopUpProps {
  mainAccount: string;
  mainAccountBalance: number;
  cardAccount: AccountType;
  onPaymentStatus: OnPaymentStatus;
  onBalanceChange?: (balance: string) => void;
}

interface TopUpFormProps extends TopUpProps {
  fullwidthButton?: boolean;
}

export const CardTopUpForm: React.FC<TopUpFormProps> = ({
  mainAccount,
  mainAccountBalance,
  cardAccount,
  fullwidthButton = false,
  onPaymentStatus,
  onBalanceChange,
}) => {
  invariant(
    cardAccount.balanceNok?.asNumber != null,
    'There must be a card balance',
  );

  const [state, dispatch] = React.useReducer(reducer, {
    newBalance: '',
    signingUrl: null,
    isLoading: false,
    cachedAmountToTransfer: undefined,
  });
  const [showFullWidthButton, setShowFullWidthButton] = React.useState(false);

  const [pay] = useMutation(EmployeePayDocument);
  const [setAutofill] = useMutation(SetAutofillDocument);
  const [refetchFirstPaint] = useLazyQuery(FirstPaintDocument, {
    fetchPolicy: 'cache-and-network',
  }); // Updated balance and autofill

  const success = React.useCallback(async () => {
    if (cardAccount.autofill) {
      await setAutofill({
        variables: {
          desiredAmount: state.newBalance,
          accountNumber: cardAccount.accountNumber,
        },
      });
    }
    await refetchFirstPaint();
    dispatch({ type: 'set-transfer-succeeded' });
    onPaymentStatus('success', cardAccount);
    formEleRef.current?.focus({ preventScroll: true });
  }, [
    onPaymentStatus,
    cardAccount,
    state.newBalance,
    setAutofill,
    refetchFirstPaint,
  ]);

  const failure = React.useCallback(() => {
    dispatch({ type: 'set-transfer-failed' });
    onPaymentStatus('failure', cardAccount);
  }, [onPaymentStatus, cardAccount]);

  const currentBalance = cardAccount.balanceNok?.asNumber
    ? cardAccount.balanceNok.asNumber
    : 0;
  const amountToTransfer = getAmountToTransfer(
    currentBalance,
    state.newBalance,
  );

  const handleSetNewBalance = React.useCallback(async () => {
    if (amountToTransfer === 0) {
      dispatch({ type: 'set-transfer-succeeded' });
      return;
    }

    dispatch({
      type: 'set-transfer-start',
      cachedAmountToTransfer: amountToTransfer,
    });

    let debtorAccount = mainAccount;
    let creditorAccount = cardAccount.accountNumber;
    let creditorName = cardAccount.name;

    if (amountToTransfer < 0) {
      [debtorAccount, creditorAccount] = [creditorAccount, debtorAccount];
      creditorName = 'Driftskonto'; // FIXME
    }

    // TODO: move some default values to backend
    try {
      const { data } = await pay({
        variables: {
          input: {
            debtorAccount,
            creditorAccount,
            creditorName,
            amount: String(Math.abs(amountToTransfer)),
            date: format(new Date(), 'yyyy-MM-dd'),
            message: 'Overføring',
            signingMethod: 'BankId',
            hidden: true,
          },
        },
      });

      const signingUrl = data?.pay.authUrl;
      // Missing signingUrl means that no signing is needed
      if (signingUrl) {
        dispatch({ type: 'set-signing-url', signingUrl });
      } else {
        success();
      }
    } catch (error) {
      failure();
      Sentry.captureException(error);
    }
  }, [cardAccount, amountToTransfer, mainAccount, success, failure, pay]);

  const renderErrorMessage = mainAccountBalance < amountToTransfer;
  const inputMessage: TextInputProps['message'] = renderErrorMessage
    ? createInputError('Ikke nok penger på driftskontoen')
    : amountToTransfer === 0
    ? { kind: 'placeholder' }
    : {
        kind: 'message',
        content: (
          <div
            css={css`
              color: var(--muted-color);
            `}
          >
            {getAmountToTransferText(
              state.cachedAmountToTransfer ?? amountToTransfer,
            )}
          </div>
        ),
      };

  const hasInputValue = state.newBalance !== '';
  const disabledButton =
    !hasInputValue || renderErrorMessage || state.isLoading;

  const id = React.useId();
  const formEleRef = React.useRef<HTMLFormElement>(null);
  return (
    <>
      <form
        key={cardAccount.accountNumber}
        ref={formEleRef}
        tabIndex={-1}
        css={noFocus}
        noValidate={true}
        onSubmit={event => {
          event.preventDefault();
          handleSetNewBalance();
        }}
      >
        <Label htmlFor={id}>Hvor mye skal stå på kortet?</Label>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            ${space([8], 'gap')};
          `}
        >
          <div
            css={css`
              flex-grow: 1;
            `}
          >
            <NewBalanceInput
              id={id}
              value={state.newBalance}
              onChange={newBalance => {
                setShowFullWidthButton(true);
                onBalanceChange?.(newBalance);
                dispatch({ type: 'set-new-balance', balance: newBalance });
              }}
              maxLength={maxBalanceLength}
              autoComplete="off"
              name="new-balance"
              placeholder={formatters.formatAmount(
                cardAccount.balanceNok.asNumber,
              )}
              onFocus={() => setShowFullWidthButton(true)}
              rightContent={{ content: 'kr', width: 13 }}
              message={hasInputValue ? inputMessage : undefined}
            />
          </div>

          {!fullwidthButton && (
            <div>
              <Button
                type="submit"
                disabled={disabledButton}
                loading={state.isLoading}
              >
                OK
              </Button>
            </div>
          )}
        </div>
        {fullwidthButton && (
          <div
            css={css`
              ${space([8], hasInputValue ? 'margin-bottom' : 'margin-top')};
              overflow: hidden;
              height: ${showFullWidthButton ? 'auto' : 0};
              opacity: ${showFullWidthButton ? 1 : 0};

              @supports (interpolate-size: allow-keywords) {
                interpolate-size: allow-keywords;
                transition: 0.2s ease-out;
                transition-property: height, opacity;
              }
            `}
          >
            <Button
              type="submit"
              disabled={disabledButton}
              loading={state.isLoading}
              fullWidth
            >
              Lagre endringer
            </Button>
          </div>
        )}
      </form>

      <div css={hasInputValue ? null : space([8], 'margin-top')}>
        <WarningMessage show={Number(state.newBalance) >= WARNING_AMOUNT} />
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          ${space([8], 'padding-vertical', 'gap')};
          ${space([16], 'padding-horizontal')};
          background-color: ${colors.grayAiry};
          border-radius: 8px;
          ${fonts.font100book};
        `}
      >
        <div>På driftskonto</div>
        <div>
          {formatters.formatAmount(mainAccountBalance, {
            currency: 'kr',
          })}
        </div>
      </div>
      <div
        css={css`
          color: var(--muted-color);
          ${fonts.font100book};
          ${space([16], 'padding-horizontal')};
          ${space([8], 'margin-top')};
        `}
      >
        For å overføre tilbake til driftskonto, velger du lavere saldo på
        kortet.
      </div>
      {state.signingUrl != null && (
        <SigningModalDialog
          signingUrl={state.signingUrl}
          onSuccess={success}
          onFailure={failure}
          onSigningChange={signingUrl => {
            dispatch({ type: 'set-signing-url', signingUrl });
          }}
        />
      )}
    </>
  );
};

const SigningModalDialog: React.FC<{
  signingUrl: State['signingUrl'];
  onSuccess: () => void;
  onFailure: () => void;
  onSigningChange: (signingUrl: State['signingUrl']) => void;
}> = props => {
  const { signingUrl, onSuccess, onFailure, onSigningChange } = props;
  const [approvePayment] = useMutation(ApprovePaymentDocument, {
    refetchQueries: [FirstPaintDocument],
    awaitRefetchQueries: true,
  });

  return (
    <OverlayContainer>
      <ModalDialog
        title="Godkjenn overføring med BankID"
        isOpen
        css={dialogMarginAdjustments}
      >
        <div css={dialogMarginAdjustments}>
          <DialogHeader>
            <div css={dialogHeadingStyle}>Godkjenn overføring med BankID</div>
            <PlainButton aria-label="Lukk" onClick={() => onFailure()}>
              <XForCloseIcon
                css={css`
                  display: block;
                `}
              />
            </PlainButton>
          </DialogHeader>
          <BankIdSign
            url={signingUrl ?? undefined}
            onSuccess={async (code, replyState) => {
              // This will re-render with no signing URL, which renders a spinner
              onSigningChange(null);

              try {
                await approvePayment({
                  variables: {
                    input: { code, state: replyState },
                  },
                });
                onSuccess();
              } catch (error) {
                onFailure();
                Sentry.captureException(error);
              }
            }}
            onError={error => {
              onFailure();
              Sentry.captureException(error);
            }}
          />
        </div>
      </ModalDialog>
    </OverlayContainer>
  );
};

const NewBalanceInput = styled(NumberValueInput)`
  text-align: initial;
  width: 100%;
`;

const DialogHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  ${space([16], 'margin-bottom')};

  @media not all and ${veryNarrowMq} {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

// The BankID iframe is only responsive down to 320px. This is used to adjust
// margins of both the dialog and the iframe so that the whole iframe fits on
// devices that are 320px wide.
const dialogMarginAdjustments = css`
  @media not all and ${narrowMq} {
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
  }

  @media not all and ${veryNarrowMq} {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
`;

const WarningMessage: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <div
      css={css`
        overflow: hidden;
        height: ${show ? 'auto' : 0};
        opacity: ${show ? 1 : 0};
        margin-bottom: ${show ? '8px' : null};
        background-color: ${colors.redLight};
        border-radius: 8px;

        @supports (interpolate-size: allow-keywords) {
          interpolate-size: allow-keywords;
          transition: 0.2s ease-out;
          transition-property: height, opacity;
        }
      `}
    >
      <div css={space([16], 'padding')}>
        <div
          css={css`
            ${space([8], 'margin-bottom')};
            ${fonts.font100medium};
          `}
        >
          Sikkerhetstips
        </div>
        <div css={fonts.font100book}>
          Unngå å ha mer penger på kortet enn nødvendig.
        </div>
      </div>
    </div>
  );
};

function getAmountToTransferText(amountToTransfer: number) {
  const formattedAmount = formatAmount(Math.abs(amountToTransfer));
  return amountToTransfer > 0
    ? `${formattedAmount} overføres fra driftskonto`
    : `${formattedAmount} overføres til driftskonto`;
}
