import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import type { FirstPaintNoSessionQuery } from '../../common-queries.generated';
import type { BroadcastScope, BroadcastSeverity } from '../../gqltypes';
import { useFirstPaintNoSessionData } from '../../hooks/use-first-paint-data';

export const BroadcastBoxWrapper = styled.div`
  ${space([16], 'margin-bottom')};
`;

export const broadcastColor: Record<BroadcastSeverity, string> = {
  Error: colors.redLight,
  Warning: colors.yellowLight,
  Info: colors.blueLight,
};

export const BroadcastWrapper = styled.div<{ severity: BroadcastSeverity }>`
  ${space([16, 32], 'padding')};
  background-color: ${({ severity }) => broadcastColor[severity]};
  border-radius: 8px;
`;

export const BroadcastBoxHeading = styled.h2`
  margin: 0;
  ${fonts.font300demi};
  ${space([8], 'margin-bottom')};
`;

export const BroadcastBox: React.FC<{
  scopes: readonly BroadcastScope[];
}> = ({ scopes }) => {
  const { data, loading } = useFirstPaintNoSessionData();

  if (loading || !data) {
    return null;
  }

  const broadcastMessages = data.broadcast;

  const broadcastMessagesToShow =
    scopes.length > 0
      ? broadcastMessages.filter(mess =>
          mess.scopes.some(scope => scopes.includes(scope)),
        )
      : broadcastMessages;

  if (broadcastMessagesToShow.length === 0) {
    return null;
  }

  return broadcastMessagesToShow
    .filter(broadcast => {
      // Temp workaround for the case where you have a broadcast with scopes ['Generic', 'Transactions'].
      // The app currently only supports 'Transactions' so we need that one. But having both means that
      // we render them twice in the tranactions list on web. This filters out one of them.
      if (!scopes.includes('Generic') && broadcast.scopes.includes('Generic')) {
        return false;
      }
      return true;
    })
    .map(broadcast => (
      <BroadcastBoxContent key={broadcast.fid} broadcast={broadcast} />
    ));
};

export type Broadcast = Omit<
  FirstPaintNoSessionQuery['broadcast'][number],
  '__typename' | 'fid'
>;

const BroadcastContentBoxBase: React.FC<{ broadcast: Broadcast }> = ({
  broadcast,
}) => (
  <BroadcastBoxWrapper>
    <BroadcastWrapper severity={broadcast.severity}>
      {broadcast.heading && (
        <BroadcastBoxHeading>{broadcast.heading}</BroadcastBoxHeading>
      )}
      <div
        css={css`
          p:first-of-type {
            margin-top: 0;
          }
          p:last-of-type {
            margin-bottom: 0;
          }
        `}
        dangerouslySetInnerHTML={{ __html: broadcast.message }}
      />
    </BroadcastWrapper>
  </BroadcastBoxWrapper>
);

export const BroadcastBoxContent = React.memo(BroadcastContentBoxBase);
