import { css } from '@emotion/react';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useHasCapability } from '../../hooks/use-capability-check';
import { pages } from '../../pages';
import { accountHasBlockedCard } from '../../utils/card';
import { OrgLink } from '../org-navigation';
import { AutofillDetailsBox } from './autofill-details-box';
import type { Card } from './types';
import { CardTopUpForm, type TopUpProps } from '.';

export const CardTopUpAndAutofill: React.FC<TopUpProps> = props => {
  const canSetAutofill = useHasCapability('CanSetAutofill');
  const accountHasAnyBlockedCards = accountHasBlockedCard(
    props.cardAccount.cards,
  );

  if (canSetAutofill == null) {
    return null;
  }

  if (accountHasAnyBlockedCards) {
    return <BlockedCardView card={accountHasAnyBlockedCards} />;
  }

  return (
    <>
      <CardTopUpForm
        onBalanceChange={() => undefined}
        fullwidthButton
        {...props}
      />
      <AutofillDetailsBox account={props.cardAccount} />
    </>
  );
};

const BlockedCardView: React.FC<{ card: Card }> = ({ card }) => {
  const isFraud = card.state === 'FraudBlocked';

  return (
    <>
      {isFraud ? (
        <div
          css={css`
            color: ${colors.wcagRed};
          `}
        >
          Kortet er midlertidig sperret
        </div>
      ) : (
        <div>Fjern frys for å åpne kortet.</div>
      )}
      <OrgLink
        css={css`
          color: var(--muted-color);
          /* Needed for the margin to take effect */
          display: inline-block;
          margin-top: 8px;
        `}
        to={
          isFraud
            ? pages.profile.getUrl()
            : `${pages.profile.getUrl()}#frys-kort`
        }
      >
        Vis valg for kortet
      </OrgLink>
    </>
  );
};
