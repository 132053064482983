import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PlainButton, fonts, shadows } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import {
  type AppNotification,
  type Kind as NotificationKind,
  useAutodismissingNotification,
  useNotifications,
} from '../../hooks/notification-context';
import { InfoIcon, WarningIcon, XForCloseIcon } from '../../icons';
import { cardStyle } from '../card-style';

const iconMap: Record<NotificationKind, React.ReactNode> = {
  // TODO: use correct icons
  info: (
    <InfoIcon
      css={css`
        display: block;
      `}
    />
  ),
  error: (
    <WarningIcon
      css={css`
        display: block;
      `}
    />
  ),
  success: (
    <InfoIcon
      css={css`
        display: block;
      `}
    />
  ),
};

const colorMap: Record<
  NotificationKind,
  { color: string; backgroundColor: string }
> = {
  info: {
    color: colors.black,
    backgroundColor: colors.white,
  },
  error: {
    color: colors.wcagRed,
    backgroundColor: colors.redLight,
  },
  success: {
    color: colors.greenTextLabel,
    backgroundColor: colors.greenLight,
  },
};

export const NotificationBar: React.FC = () => {
  const { unread } = useNotifications();

  return (
    <div
      css={css`
        position: -webkit-sticky;
        position: sticky;
        top: 16px;
        z-index: 1;
      `}
    >
      <NotificationsList>
        {unread.slice(0, 10).map(notification => (
          <NotificationItem key={notification.id} {...notification} />
        ))}
      </NotificationsList>
    </div>
  );
};

const NotificationItem: React.FC<AppNotification> = notification => {
  const ele = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (ele.current) {
      ele.current.style.setProperty('opacity', '1');
    }
  }, []);

  const { dismiss, resetTimeout, hideAfterTimeout } =
    useAutodismissingNotification(notification);

  const { title, body, kind } = notification;
  const { color, backgroundColor } = colorMap[kind];

  return (
    <li
      ref={ele}
      css={css`
        ${cardStyle};
        ${shadows.mediumShadow};
        padding: 16px;
        margin-bottom: 16px;
        width: 100%;
        word-break: break-word;
        position: relative;
        opacity: 0;
        transition: opacity 0.5s;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: 8px;
        gap: 8px;
        background-color: ${backgroundColor};
        color: ${color};
      `}
      onPointerEnter={resetTimeout}
      onPointerLeave={hideAfterTimeout}
      role={kind === 'error' ? 'alert' : 'status'}
    >
      <div>{iconMap[kind]}</div>
      <div>
        {title && <Title>{title}</Title>}
        {body}
      </div>
      <div>
        <PlainButton
          onClick={dismiss}
          aria-label="Lukk"
          css={css`
            display: block;
            &:focus {
              outline: none;
              color: ${colors.black};
            }
          `}
        >
          <XForCloseIcon
            css={css`
              display: block;
            `}
          />
        </PlainButton>
      </div>
    </li>
  );
};

const NotificationsList = styled.ol`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 8px;
  list-style: none;
  margin: 0;
  bottom: 0;
  width: 100%;

  @media (min-width: 400px) {
    width: 300px;
  }
`;

const Title = styled.div`
  ${fonts.font200medium};
`;
