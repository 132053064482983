import { css } from '@emotion/react';
import { useBatchedQuery, useUnbatchedQuery } from 'bank-common-client';
import { centralBankInterestRateFormatted } from 'folio-central-interest-rate';
import { space } from 'folio-common-components';
import { formatters, isArrayOfLength, notEmpty } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import type { FirstPaintQuery } from '../../common-queries.generated';
import type { OnPaymentStatus } from '../../components/CardTopUp';
import { CardTopUpAndAutofill } from '../../components/CardTopUp/autofill-and-topup';
import { SkeletonLine } from '../../components/SkeletonLine';
import { OrgButtonLink } from '../../components/org-navigation';
import type { AccountState, AccountType } from '../../gqltypes';
import { useNotifications } from '../../hooks/notification-context';
import {
  useHasCapability,
  useHasFeatureToggle,
} from '../../hooks/use-capability-check';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import {
  CardIcon,
  LockIcon,
  MoneyBagCleanIcon,
  MultipleCardsIcon,
  PlusIcon,
  SmallNewViewFor200Icon,
} from '../../icons';
import { pages } from '../../pages';
import { accountHasBlockedCard, allCardsAreExpired } from '../../utils/card';
import { GetEmployeesWithCardsDocument } from '../employees/queries.generated';
import { getComputedEarmarks } from '../savings-account';
import { EarmarksDocument } from '../savings-account/queries.generated';

export type AccountSummary = {
  readonly type: AccountType;
  readonly title: string;
  readonly amount: number;
  readonly icon: React.ReactNode;
  readonly expandedContent: React.ReactElement | null;
  readonly allCardsExpired?: boolean;
};

type Account = FirstPaintQuery['accountsInfo']['accounts'][number];

function getAccountsByType<
  T extends { state: AccountState; type: AccountType },
>(accounts: readonly T[], type: AccountType): readonly T[] {
  return accounts.filter(acc => acc.state === 'Active' && acc.type === type);
}

function sumBalanceForAccounts(accounts: readonly Account[]) {
  return accounts.reduce(
    (previousValue, currentValue) =>
      previousValue + (currentValue.balanceNok?.asNumber ?? 0),
    0,
  );
}

const SavingsAccountManagement: React.FC<{
  amount: number;
  hasSavingsAccount: boolean;
}> = ({ amount, hasSavingsAccount }) => {
  React.useEffect(() => {
    if (hasSavingsAccount) {
      pages.moveMoneyToSavingsAccount.loadableComponent.preload();

      if (amount > 0) {
        pages.moveMoneyFromSavingsAccount.loadableComponent.preload();
      }
    } else {
      pages.orderSavingsAccount.loadableComponent.preload();
    }
  }, [amount, hasSavingsAccount]);

  if (hasSavingsAccount) {
    return (
      <div>
        {amount > 0 ? (
          <OrgButtonLink
            to={pages.moveMoneyFromSavingsAccount.getUrl()}
            fullWidth={true}
            level="secondary"
          >
            Hent ut penger
          </OrgButtonLink>
        ) : (
          <>
            Sett av penger som skal holdes unna daglig drift. Sparerente opptil{' '}
            {centralBankInterestRateFormatted}.
          </>
        )}
        <OrgButtonLink
          to={pages.moveMoneyToSavingsAccount.getUrl()}
          fullWidth={true}
          icon={amount > 0 ? <PlusIcon /> : undefined}
          css={space([16], 'margin-top')}
        >
          {amount > 0 ? 'Sett av mer' : 'Sett i gang'}
        </OrgButtonLink>
      </div>
    );
  }

  return (
    <div>
      Topp sparerente, og kontroll på midler som skal holdes utenfor den daglige
      driften.
      <div
        css={css`
          ${space([16], 'margin-vertical')};
          display: flex;
          justify-content: center;
        `}
      >
        <a
          href="https://folio.no/les/sparekonto"
          css={css`
            color: ${colors.black};
            display: inline-flex;
            ${space([4], 'gap')};
            align-items: center;
          `}
        >
          Les om kontoen
          <SmallNewViewFor200Icon />
        </a>
      </div>
      <OrgButtonLink to={pages.orderSavingsAccount.getUrl()} fullWidth={true}>
        Sett i gang
      </OrgButtonLink>
    </div>
  );
};

const EarmarkingManagement: React.FC<{
  amount: number;
  hasSavingsAccount: boolean;
}> = ({ amount, hasSavingsAccount }) => {
  const { data } = useBatchedQuery(EarmarksDocument);

  const computedEarmarks = data?.organization
    ? getComputedEarmarks(data.organization)
    : undefined;

  React.useEffect(() => {
    pages.earmarks.loadableComponent.preload();
  }, [amount, hasSavingsAccount]);

  if (hasSavingsAccount) {
    if (!computedEarmarks) {
      return (
        <>
          <div css={space([8], 'margin-bottom')}>
            <SkeletonLine width="100%" maxWidth="100%" />
          </div>
          <div css={space([8], 'margin-bottom')}>
            <SkeletonLine
              width="100%"
              maxWidth="100%"
              css={css`
                animation-delay: 100ms;
              `}
            />
          </div>
          <OrgButtonLink
            to={pages.earmarks.getUrl()}
            fullWidth={true}
            css={space([8], 'margin-top')}
          >
            Se og endre sparing
          </OrgButtonLink>
        </>
      );
    }

    return (
      <>
        {computedEarmarks.map(earmark => (
          <div
            key={earmark.kind === 'real' ? earmark.fid : 'other'}
            css={space([8], 'margin-bottom')}
          >
            <b>
              {formatters.formatAmount(earmark.amount, {
                currency: 'kr',
              })}
            </b>{' '}
            til {earmark.name.toLowerCase()}
          </div>
        ))}
        <OrgButtonLink
          to={pages.earmarks.getUrl()}
          fullWidth={true}
          css={space([8], 'margin-top')}
        >
          Se og endre sparing
        </OrgButtonLink>
      </>
    );
  }

  return (
    <div>
      Topp sparerente, og kontroll på midler som skal holdes utenfor den daglige
      driften.
      <div
        css={css`
          ${space([16], 'margin-vertical')};
          display: flex;
          justify-content: center;
        `}
      >
        <a
          href="https://folio.no/les/sparekonto"
          css={css`
            color: ${colors.black};
            display: inline-flex;
            ${space([4], 'gap')};
            align-items: center;
          `}
        >
          Les om kontoen
          <SmallNewViewFor200Icon />
        </a>
      </div>
      <OrgButtonLink to={pages.orderSavingsAccount.getUrl()} fullWidth={true}>
        Sett i gang
      </OrgButtonLink>
    </div>
  );
};

export function getSummaryForEarmarkings(
  accounts: readonly Account[],
  canAuthorizePayment: boolean,
): AccountSummary | null {
  const type: AccountType = 'Savings';
  const savingsAccounts = getAccountsByType(accounts, type);
  const amount = sumBalanceForAccounts(savingsAccounts);
  const hasSavingsAccount = savingsAccounts.length > 0;

  return {
    type,
    title: 'Sparing',
    amount,
    icon: <MoneyBagCleanIcon />,
    expandedContent: canAuthorizePayment ? (
      <EarmarkingManagement
        amount={amount}
        hasSavingsAccount={hasSavingsAccount}
      />
    ) : null,
  };
}

// public for testing
export function getSummaryForSavingsAccounts(
  accounts: readonly Account[],
  canListEarmarks: boolean,
): AccountSummary | null {
  const type: AccountType = 'Savings';
  const savingsAccounts = getAccountsByType(accounts, type);
  const amount = sumBalanceForAccounts(savingsAccounts);
  const hasSavingsAccount = savingsAccounts.length > 0;

  return {
    type,
    title: 'Sparing',
    amount,
    icon: <MoneyBagCleanIcon />,
    expandedContent: canListEarmarks ? (
      <SavingsAccountManagement
        amount={amount}
        hasSavingsAccount={hasSavingsAccount}
      />
    ) : null,
  };
}

// public for testing
export function getSummaryForTaxAccounts(
  accounts: readonly Account[],
  canAuthorizePayment: boolean,
): AccountSummary | null {
  const type: AccountType = 'Tax';
  const [taxAccount] = getAccountsByType(accounts, type);

  if (!taxAccount) {
    return null;
  }

  const hasTaxBalance = (taxAccount?.balanceNok?.asNumber ?? 0) > 0;

  const helpText = hasTaxBalance
    ? 'Satt av til skatt av lønn.'
    : 'Skattetrekk av lønn holdes av her til den skal betales.';

  return {
    type,
    title: 'Skattetrekk',
    amount: taxAccount.balanceNok?.asNumber || 0,
    icon: <LockIcon />,
    expandedContent: canAuthorizePayment ? (
      <>
        {helpText}
        <TaxButtons showPayButton={hasTaxBalance} />
      </>
    ) : null,
  };
}

// public for testing
export function getSummaryForCardAccounts(
  accounts: readonly Account[],
  mainAccount: string | undefined,
  canAuthorizePayment: boolean,
  mainAccountBalance: number,
): AccountSummary | null {
  const type: AccountType = 'Card';
  const cardAccounts = getAccountsByType(accounts, type);
  const amount = sumBalanceForAccounts(cardAccounts);

  if (cardAccounts.length === 0) {
    return null;
  }

  if (isArrayOfLength(cardAccounts, 1)) {
    const [cardAccount] = cardAccounts;
    const accountHasAnyBlockedCards = accountHasBlockedCard(cardAccount.cards);
    const allCardsExpired = allCardsAreExpired(cardAccount.cards);

    return {
      type,
      amount,
      icon: <CardIcon />,
      allCardsExpired,
      title:
        accountHasAnyBlockedCards?.state === 'Blocked'
          ? 'Fryst kort'
          : allCardsExpired
          ? 'Utgått kort'
          : cardAccount.autofill
          ? 'Kort med påfyll'
          : 'Kortet',
      expandedContent:
        canAuthorizePayment && mainAccount && !allCardsExpired ? (
          <SummaryCardTopUp
            cardAccount={cardAccount}
            mainAccount={mainAccount}
            mainAccountBalance={mainAccountBalance}
          />
        ) : null,
    };
  } else {
    return {
      type,
      amount,
      icon: <MultipleCardsIcon />,
      title: 'Kortene',
      expandedContent: canAuthorizePayment ? (
        <MultipleCardsExpandedContent cardAccounts={cardAccounts} />
      ) : null,
    };
  }
}

const MultipleCardsExpandedContent: React.FC<{
  cardAccounts: readonly Account[];
}> = ({ cardAccounts }) => {
  useBatchedQuery(GetEmployeesWithCardsDocument);

  React.useEffect(() => {
    pages.employees.loadableComponent.preload();
  }, []);

  return (
    <>
      Totalbeløp fordelt på {cardAccounts.length} kort
      <OrgButtonLink
        to={pages.employees.getUrl()}
        level="secondary"
        fullWidth={true}
        css={space([16], 'margin-top')}
      >
        Endre kortbeløp
      </OrgButtonLink>
    </>
  );
};

const SummaryCardTopUp: React.FC<{
  cardAccount: Account;
  mainAccount: string;
  mainAccountBalance: number;
}> = ({ cardAccount, mainAccount, mainAccountBalance }) => {
  const { show: showNotification } = useNotifications();
  const handlePaymentStatus = React.useCallback<OnPaymentStatus>(
    status => {
      if (status === 'failure') {
        showNotification({ body: 'Kunne ikke endre beløpet på kortet' });
      }
    },
    [showNotification],
  );

  return (
    <CardTopUpAndAutofill
      cardAccount={cardAccount}
      mainAccountBalance={mainAccountBalance}
      mainAccount={mainAccount}
      onPaymentStatus={handlePaymentStatus}
    />
  );
};

const TaxButtons: React.FC<{
  showPayButton: boolean;
}> = ({ showPayButton }) => {
  React.useEffect(() => {
    pages.transferToTaxAccount.loadableComponent.preload();

    if (showPayButton) {
      pages.newPayment.loadableComponent.preload();
    }
  }, [showPayButton]);

  return (
    <>
      <OrgButtonLink
        to={pages.transferToTaxAccount.getUrl()}
        level="secondary"
        fullWidth={true}
        css={space([16], 'margin-top')}
      >
        Overfør hit
      </OrgButtonLink>

      {showPayButton && (
        <OrgButtonLink
          to={`${pages.newPayment.getUrl()}?skattetrekk`}
          level="primary"
          fullWidth={true}
          css={space([16], 'margin-top')}
        >
          Betal skatten
        </OrgButtonLink>
      )}
    </>
  );
};

export function useAccountSummaries(): null | readonly AccountSummary[] {
  const { data } = useFirstPaintData();
  const canAuthorizePayment = useHasCapability('CanAuthorizePayment');
  const canListEarmarks = useHasCapability('CanListPayments'); // Use this as a proxy for now
  const showEarmarkings = useHasFeatureToggle('earmarkings');
  useUnbatchedQuery(EarmarksDocument, { skip: showEarmarkings !== true }); // Preload

  const accounts = data?.accountsInfo.accounts;
  const mainAccount = data?.organization.operationalAccount?.accountNumber;
  const mainAccountBalance =
    data?.organization.operationalAccount?.balanceNok?.asNumber ?? 0;

  return React.useMemo(() => {
    if (
      accounts == null ||
      canAuthorizePayment == null ||
      canListEarmarks == null
    ) {
      return null;
    }

    return [
      // if there is "no" (i.e. no access to) main account, the user does not have access to seeing savings balance
      mainAccount == null
        ? null
        : showEarmarkings
        ? getSummaryForEarmarkings(accounts, canListEarmarks)
        : getSummaryForSavingsAccounts(accounts, canAuthorizePayment),
      getSummaryForCardAccounts(
        accounts,
        mainAccount,
        canAuthorizePayment,
        mainAccountBalance,
      ),
      getSummaryForTaxAccounts(accounts, canAuthorizePayment),
    ].filter(notEmpty);
  }, [
    accounts,
    canAuthorizePayment,
    canListEarmarks,
    mainAccount,
    mainAccountBalance,
    showEarmarkings,
  ]);
}
