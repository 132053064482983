import { useApolloClient, useLazyQuery } from '@apollo/client';
import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { useBatchedQuery, useUnbatchedQuery } from 'bank-common-client';
import {
  PlainButton,
  TextButton,
  TextInput,
  fonts,
  noFocus,
  space,
} from 'folio-common-components';
import { formatters, isArrayOfAtLeastOne, partition } from 'folio-common-utils';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useMediaLayout } from 'use-media';
import { v4 as uuidv4 } from 'uuid';
import { ListStatusMessage } from '../../components/list-status-message';
import { NumberBadge } from '../../components/number-badge';
import { LedgerCategoriesDocument } from '../../components/shared/queries.generated';
import {
  tabActiveStyle,
  tabBarStyle,
  tabStyle,
} from '../../components/tab-nav';
import { useEventFilters } from '../../event-filter-context';
import { useChangedEvents } from '../../hooks/use-changed-events';
import { useTitle } from '../../hooks/use-title';
import { MagnifyingGlassIcon, XForNoIcon } from '../../icons';
import { touchFeedback } from '../../styles/touch-feedback';
import { isProbablyMobile } from '../../utils/device';
import { InboxZero } from './InboxZero';
import {
  LoadingTransaction,
  LoadingTransactionList,
  TransactionList,
} from './TransactionList';
import {
  EventPageDocument,
  GetNewEventsAndBalanceDocument,
} from './queries.generated';
import { eventsSorter } from './sorter';

const SEARCH_TERM_MIN_LENGTH = 3;

// Use pointer: coarse as a proxy for soft keyboard
const hasSoftKeyboard = window.matchMedia('(pointer: coarse)').matches;

const searchParameter = 'sok';

function normalizeSearchTerm(searchTerm: string) {
  // Remove extra spaces, except at the end where it's significant.
  // "subst" will find "substring", but "subst " will not.
  const normalized = searchTerm.trimStart().replace(/\s+/g, ' ');

  if (normalized.length < SEARCH_TERM_MIN_LENGTH) {
    return '';
  }

  return normalized;
}

const NonMemoizedNewTransactionsView: React.FC = () => {
  // Prefetch categories
  useBatchedQuery(LedgerCategoriesDocument);

  const [params, setParams] = useSearchParams();
  const [searchTermValue, setSearchTermValue] = React.useState(
    params.get(searchParameter) ?? '',
  );
  const [searchTerm, { flush: submitSearchTerm }] = useDebounce(
    normalizeSearchTerm(searchTermValue),
    normalizeSearchTerm(searchTermValue) === '' ? 0 : 300, // Don't debounce when clearing
  );
  useTitle(searchTerm || 'Oversikt');
  const [searchVisible, setSearchVisible] = React.useState(searchTerm !== '');

  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  });

  const searchId = React.useRef(uuidv4());
  React.useEffect(() => {
    if (searchTerm === '') {
      searchId.current = uuidv4();
    }
  }, [searchTerm]);

  const { eventFilters } = useEventFilters();

  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const incompleteOnly = eventFilters.status === 'incomplete';
  const { error, data, loading, fetchMore, startPolling, stopPolling } =
    useUnbatchedQuery(EventPageDocument, {
      variables: {
        incompleteOnly,
        searchTerm,
        before: undefined,
        includeIncompleteCount: true,
        searchId: searchTerm === '' ? undefined : searchId.current,
      },
      // Using the default policy here ('cache-first') leads to a bug where the
      // data does not correspond the search term.  To reproduce:
      // 1. Quickly write "fi" in the search input
      // 2. Remove "i", wait ~200 ms and remove "f"
      // The search field now has the value "", but the rendered data
      // corresponds to a search for "f". This might be fixed in the latest
      // Apollo client version, but we currently use an older version.
      fetchPolicy: 'cache-and-network',
      // pollInterval: ten minutes,
      // skipPollAttempt: () => document.hidden, // Apollo client 3.9
    });

  React.useEffect(() => {
    startPolling(1000 * 60 * 10); // Ten minutes

    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const client = useApolloClient();
  const [getEvents] = useLazyQuery(GetNewEventsAndBalanceDocument, {
    fetchPolicy: 'network-only',
  });
  const handleChangedEvents = React.useCallback(
    (eventFids: readonly string[]) => {
      Sentry.addBreadcrumb({
        message: 'Got changed events',
        data: { eventFids },
      });
      const apolloCache = client.cache.extract();
      const [eventsInCache, eventsNotInCache] = partition(eventFids, fid => {
        const stringifiedKey = JSON.stringify({ fid });
        return (
          Object.hasOwn(apolloCache, `Event:${stringifiedKey}`) ||
          Object.hasOwn(apolloCache, `SalaryPayment:${stringifiedKey}`)
        );
      });

      if (isArrayOfAtLeastOne(eventsInCache)) {
        // Fetch updated events
        getEvents({ variables: { eventFids: eventsInCache } });
      }

      if (isArrayOfAtLeastOne(eventsNotInCache)) {
        // Fetch any events that don't exist in the cache
        // This fetchMore call won't overwrite the `earliestTimestampInPage`,
        // since we always use the earliest date we've seen, see the merge
        // function for `eventPage` in bank-common-client.
        fetchMore({
          variables: {
            includeIncompleteCount: true,
            incompleteOnly,
            searchTerm,
            searchId: undefined,
          },
        });
      }
    },
    [client, getEvents, fetchMore, incompleteOnly, searchTerm],
  );

  useChangedEvents(handleChangedEvents);

  // Update URL with search term while typing
  React.useLayoutEffect(() => {
    setParams(
      params => {
        if (searchTerm) {
          params.set(searchParameter, searchTerm);
        } else {
          params.delete(searchParameter);
        }
        return params;
      },
      { replace: true },
    );
  }, [searchTerm, setParams]);

  const listEleRef = React.useRef<HTMLDivElement>(null);
  const inputEleRef = React.useRef<HTMLInputElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const listEle = listEleRef.current;

    function focusListEle() {
      // If we get a touchmove on the list, the user probably wants to
      // scroll the list, so dismiss the keyboard by focusing the list
      if (document.activeElement === inputEleRef.current) {
        listEle?.focus({ preventScroll: true });
      }
    }

    // Add this in an effect to be able to use `passive: true`
    listEle?.addEventListener('touchmove', focusListEle, {
      passive: true,
    });

    return () => listEle?.removeEventListener('touchmove', focusListEle);
  });

  // Focus the search field on pressing "/"
  React.useEffect(() => {
    function handleSearchKeyboardShortcut(event: KeyboardEvent) {
      if (event.target instanceof Element) {
        const targetIsInput = event.target.matches('input, textarea, select');
        if (event.key === '/' && !targetIsInput) {
          setSearchVisible(true);
          window.requestAnimationFrame(() => {
            // On mobile, the input will focus, and scrollIntoView will be called
            inputEleRef.current?.focus({ preventScroll: isProbablyMobile });
            inputEleRef.current?.select();
          });
          event.preventDefault();
        }
      }
    }

    window.addEventListener('keydown', handleSearchKeyboardShortcut);

    return () => {
      window.removeEventListener('keydown', handleSearchKeyboardShortcut);
    };
  }, []);

  const visibleSearchCancelButton = searchTermValue !== '';
  const earliestTimestamp = data?.events.earliestTimestampInPage;
  const incompleteCount = data?.events.incompleteCount ?? undefined;
  const combinedEvents = data?.events.combinedItems;
  const events = React.useMemo(() => {
    if (!combinedEvents) {
      return [];
    }

    return Array.from(combinedEvents).sort(eventsSorter);
  }, [combinedEvents]);

  const searchEleId = React.useId();

  return (
    <>
      <div
        ref={wrapperRef}
        css={css`
          /* Create a stacking context */
          position: relative;
          z-index: 0;
          ${space([48], 'margin-top')};

          /*
          Make sure the page is high enough, to avoid the page jumping when there are no search results.
          Also make sure the intersection-observed element is outside of the viewport to avoid triggering
          extra queries.
          TODO: look into the exact calculation for this.
          */
          min-height: calc(100vh + 500px);
        `}
      >
        <TransactionFilter
          loading={loading}
          incompleteCount={incompleteCount}
          searchEleId={searchEleId}
          searchVisible={searchVisible}
          onSearchClick={() => {
            setSearchVisible(!searchVisible);
            setSearchTermValue('');
          }}
        />

        <div id={searchEleId}>
          {searchVisible ? (
            <form
              css={space([32], 'margin-bottom')}
              role="search"
              onSubmit={event => {
                event.preventDefault();
                submitSearchTerm();

                if (hasSoftKeyboard) {
                  listEleRef.current?.focus({ preventScroll: true });
                }
              }}
            >
              <TextInput
                inputRef={inputEleRef}
                aria-label="Søk i bevegelser"
                icon={<MagnifyingGlassIcon />}
                value={searchTermValue}
                onChange={searchTerm => setSearchTermValue(searchTerm)}
                onKeyDown={event => {
                  if (event.key === 'Escape') {
                    if (searchTermValue === '') {
                      listEleRef.current?.focus({ preventScroll: true });
                      setSearchVisible(false);
                    } else {
                      setSearchTermValue('');
                    }
                  }
                }}
                onFocus={() => {
                  if (isProbablyMobile) {
                    // Scroll input to the top when focusing it Safari on iOS
                    // insists on re-centering the input on focus. A double rAF
                    // solves this in most cases.
                    window.requestAnimationFrame(() => {
                      window.requestAnimationFrame(() => {
                        wrapperRef.current?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      });
                    });
                  }
                }}
                enterKeyHint="search"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                placeholder="Søk i bevegelser"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!isFirstRender.current}
                maxLength={200}
                rightContent={{
                  content: (
                    <PlainButton
                      aria-label="Nullstill"
                      aria-hidden={!visibleSearchCancelButton}
                      onClick={() => {
                        setSearchTermValue('');

                        if (hasSoftKeyboard) {
                          inputEleRef.current?.focus({ preventScroll: true });
                        } else {
                          listEleRef.current?.focus({ preventScroll: true });
                        }
                      }}
                      css={css`
                        display: block;
                        ${touchFeedback};
                        transition: 0.1s opacity ease-out, 0.1s scale ease-out;
                        opacity: ${visibleSearchCancelButton ? null : '0'};
                        scale: ${visibleSearchCancelButton ? null : '.8'};

                        /* Increase the touch area */
                        padding: 12px;
                        margin-right: -16px;
                      `}
                      // Having this in the tab order is in most cases just annoying,
                      // and it's easy the clear the field anyway.
                      tabIndex={-1}
                    >
                      <XForNoIcon
                        css={css`
                          display: block;
                        `}
                      />
                    </PlainButton>
                  ),
                  width: 22,
                  interactable: visibleSearchCancelButton,
                }}
                css={css`
                  scroll-margin-top: 16px;
                `}
              />
            </form>
          ) : null}
        </div>

        {error && !data ? (
          <ListStatusMessage>
            Klarte ikke å hente bevegelsene. Vennligst prøv igjen!
          </ListStatusMessage>
        ) : !data ? (
          // Cache incompleteCount and use as count here
          <LoadingTransactionList count={40} />
        ) : events.length === 0 ? (
          <EmptyTransactionList
            hasActiveFilter={searchTerm !== ''}
            incompleteOnly={incompleteOnly}
          />
        ) : (
          <div ref={listEleRef} tabIndex={-1} css={noFocus}>
            <MemoizedTransactionList
              events={events}
              incompleteCount={data?.events.incompleteCount ?? undefined}
            />

            {/*
            Show skeleton transactions as a loading indicator while fetching
            more transactions. As long as there are more transactions to load,
            we set off space for these skeleton transactions to avoid unnecessary
            jumps in the scrollbar.
            */}
            {earliestTimestamp
              ? Array.from({ length: 4 }).map((_, index, array) => (
                  <div
                    key={index}
                    css={css`
                      visibility: ${isLoadingMore ? 'visible' : 'hidden'};
                      opacity: ${1 - index / array.length};
                    `}
                  >
                    <LoadingTransaction index={index} />
                  </div>
                ))
              : null}
          </div>
        )}
      </div>

      <InfiniteScrollTrigger
        onTrigger={async () => {
          // FIXME stop in case of error
          if (!earliestTimestamp) {
            return;
          }

          setIsLoadingMore(true);
          try {
            await fetchMore({
              variables: {
                before: earliestTimestamp,
                includeIncompleteCount: false,
                searchId: undefined,
              },
            });
          } catch {
            // FIXME: handle this somehow
          }
          setIsLoadingMore(false);
        }}
      />
    </>
  );
};

const EmptyTransactionList: React.FC<{
  hasActiveFilter: boolean;
  incompleteOnly: boolean;
}> = ({ hasActiveFilter, incompleteOnly }) => {
  if (hasActiveFilter) {
    return (
      <div
        css={css`
          text-align: center;
        `}
      >
        <div css={fonts.font500bold}>Finnes ikke</div>
        <p>Ingen bevegelser passer med søket ditt.</p>
      </div>
    );
  }

  return incompleteOnly ? (
    <InboxZero />
  ) : (
    <p
      css={css`
        ${space([48, 48, 104], 'margin-top')};
        text-align: center;
        color: var(--muted-color);
      `}
    >
      Her vil bevegelser på kort og konto vises. Sett inn penger for å begynne!
    </p>
  );
};

const InfiniteScrollTrigger: React.FC<{
  onTrigger: () => Promise<void>;
}> = ({ onTrigger }) => {
  const eleRef = React.useRef(null);

  React.useEffect(() => {
    const ele = eleRef.current;
    if (!ele) {
      return;
    }

    const options = { rootMargin: '500px' };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          onTrigger();
        }
      });
    }, options);

    observer.observe(ele);

    return () => observer.disconnect();
  }, [onTrigger]);

  return <div ref={eleRef} />;
};

export const TransactionsView = React.memo(NonMemoizedNewTransactionsView);
const MemoizedTransactionList = React.memo(TransactionList);

interface TransactionFilterProps {
  loading: boolean;
  incompleteCount: number | undefined;
  onSearchClick: () => void;
  searchEleId: string;
  searchVisible: boolean;
}

export const TransactionFilter: React.FC<TransactionFilterProps> = ({
  loading,
  incompleteCount,
  onSearchClick,
  searchEleId,
  searchVisible,
}) => {
  // Keep a cached value so that we can show something while
  // another search is in progress
  const [cachedIncompleteCount, setCachedIncompleteCount] = React.useState(
    incompleteCount ?? 0,
  );

  React.useLayoutEffect(() => {
    if (incompleteCount != null && !loading) {
      setCachedIncompleteCount(incompleteCount);
    }
  }, [incompleteCount, loading]);

  const isWide = useMediaLayout('(min-width: 420px)');
  const { eventFilters, setEventFilters } = useEventFilters();

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 8px;
        flex-wrap: wrap;
        ${tabBarStyle};
        ${space([32], 'margin-bottom')};
      `}
    >
      <div>
        <PlainButton
          css={[tabStyle, eventFilters.status === 'all' && tabActiveStyle]}
          onClick={() => setEventFilters({ status: 'all' })}
          aria-pressed={eventFilters.status === 'all'}
          aria-label="Siste bevegelser"
        >
          {isWide ? 'Siste bevegelser' : 'Siste'}
        </PlainButton>
        <PlainButton
          css={[
            tabStyle,
            eventFilters.status === 'incomplete' && tabActiveStyle,
          ]}
          onClick={() => setEventFilters({ status: 'incomplete' })}
          aria-pressed={eventFilters.status === 'incomplete'}
        >
          Mangler noe
          {cachedIncompleteCount > 0 && (
            <NumberBadge
              aria-label={`(${cachedIncompleteCount})`}
              color="black"
              css={css`
                margin-left: 8px;
              `}
              title={formatters.formatAmount(cachedIncompleteCount)}
            >
              {cachedIncompleteCount < 100 ? cachedIncompleteCount : '99+'}
            </NumberBadge>
          )}
        </PlainButton>
      </div>
      <div>
        <TextButton
          onClick={() => onSearchClick()}
          icon={
            <MagnifyingGlassIcon
              css={css`
                margin-top: -3px;
              `}
            />
          }
          aria-expanded={searchVisible}
          aria-controls={searchEleId}
          size="large"
        >
          Søk
        </TextButton>
      </div>
    </div>
  );
};
