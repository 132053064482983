import { css } from '@emotion/react';
import { useFocusRing } from '@react-aria/focus';
import { useSwitch } from '@react-aria/switch';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import type { AriaSwitchProps } from '@react-types/switch';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { Spinner } from '../Spinner';
import { space } from '../spacing';

interface Props extends AriaSwitchProps {
  isLoading?: boolean;
}

export const Switch: React.FC<Props> = props => {
  const { isLoading, children, isDisabled } = props;
  const state = useToggleState(props);
  const ref = React.useRef<HTMLInputElement>(null);
  const { inputProps } = useSwitch(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <label
      css={css`
        display: inline-flex;
        align-items: center;
        position: relative;
        vertical-align: middle;
        cursor: ${isDisabled ? 'default' : 'pointer'};
      `}
      aria-busy={isLoading ? 'true' : undefined}
    >
      <VisuallyHidden>
        <input
          {...inputProps}
          {...focusProps}
          ref={ref}
          disabled={isDisabled}
        />
      </VisuallyHidden>
      <svg
        width={52}
        height={30}
        aria-hidden="true"
        css={css`
          overflow: visible;
        `}
      >
        <rect
          x={-1}
          y={0}
          width={52}
          height={30}
          rx={15}
          fill={
            isDisabled
              ? colors.grayShadow
              : state.isSelected
              ? colors.blue
              : colors.wcagGray
          }
          css={css`
            transition: fill 0.2s;
          `}
        />
        {/* Checkmark for selected state */}
        {state.isSelected && isLoading ? null : (
          <path
            d="M19.5 11l-7 8l-4 -4"
            fill="none"
            stroke={isDisabled ? colors.wcagNonText : '#fff'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}

        {/* Cross for unselected state */}
        {!state.isSelected && isLoading ? null : (
          <path
            d="M41.5 9.5l-11 11m0 -11l11 11"
            stroke={isDisabled ? colors.wcagNonText : '#fff'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}

        <circle
          cx={state.isSelected ? 36 : 14}
          cy={15}
          r={11}
          fill={isDisabled ? colors.wcagNonText : '#fff'}
          css={css`
            transition: cx 0.2s;
          `}
        />
        {isFocusVisible && !isDisabled && (
          <rect
            x={-4}
            y={-3}
            width={58}
            height={36}
            rx={18}
            fill="none"
            stroke={colors.blue}
            strokeWidth={2}
          />
        )}
      </svg>
      {/* Render this outside the <svg> since CSS animations on SVG child
      elements aren't GPU accelerated (at least not in Chrome). */}
      {isLoading && (
        <Spinner
          color={isDisabled ? colors.wcagNonText : '#fff'}
          size={16}
          css={css`
            position: absolute;
            top: 7px;
            left: ${state.isSelected ? '6px' : '28px'};
          `}
        />
      )}
      {children ? (
        <span css={space([8], 'margin-left')}>{children}</span>
      ) : null}
    </label>
  );
};
