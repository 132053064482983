import styled from '@emotion/styled';
import { space } from 'folio-common-components';
import * as React from 'react';
import { Page } from '../Page';
import { Brand } from './brand';
import { HeaderBar } from './header-bar';
import { LogOutLink } from './log-out';
import type { Props } from './types';

export { LogOutLink } from './log-out';
export { Brand } from './brand';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  ${space([16, 24], 'padding-vertical')};
`;

export const MinimalHeaderShell: React.FC<{
  children: React.ReactNode;
}> = props => (
  <HeaderBar>
    <Page>
      <Grid {...props} />
    </Page>
  </HeaderBar>
);

export const MinimalHeaderBar: React.FC<Props> = ({
  link,
  showLogoutLink = true,
}) => (
  <MinimalHeaderShell>
    <Brand link={link} />
    {showLogoutLink ? <LogOutLink /> : null}
  </MinimalHeaderShell>
);
