import { useMutation } from '@apollo/client';
import { autofillConstants } from 'bank-common';
import { formatters } from 'folio-common-utils';
import * as React from 'react';
import { StopAutofillDocument } from '../../common-client-queries.generated';
import type { AutofillAccount } from './types';

const { desiredAmountAsNumber } = autofillConstants;

export function useDisableAutofillIfChangeBelow(
  account: AutofillAccount,
  newAccountBalance: number,
  skipStoppingAutofill: boolean,
) {
  const { autofill, accountNumber } = account;
  const [stopAutofillMutation, { called: hasStoppedAutofill }] = useMutation(
    StopAutofillDocument,
    { variables: { accountNumber } },
  );
  const [hasCompleted, setHasCompleted] = React.useState(
    skipStoppingAutofill || !autofill,
  );

  const oldAutofillAmount = React.useRef(
    autofill ? Number(autofill.desiredAmount) : desiredAmountAsNumber,
  );

  const newBalanceBelowAutofillLimit =
    newAccountBalance < oldAutofillAmount.current;

  React.useEffect(() => {
    if (!autofill || hasStoppedAutofill || skipStoppingAutofill) {
      return;
    }
    if (newBalanceBelowAutofillLimit) {
      stopAutofillMutation()
        .then(() => {
          setHasCompleted(true);
        })
        .catch(() => {
          // ignore
        });
    }
  }, [
    autofill,
    hasStoppedAutofill,
    skipStoppingAutofill,
    newAccountBalance,
    newBalanceBelowAutofillLimit,
    stopAutofillMutation,
  ]);

  return { hasCompleted, newBalanceBelowAutofillLimit };
}

export function toNumberWithFallback(amount: string) {
  return amount === '' ? 0 : Number(amount);
}

export function getAmountToTransfer(
  currentBalance: number,
  newBalance: string,
): number {
  const newBalanceAsNumber = toNumberWithFallback(newBalance);
  let amountToTransfer = 0;
  // If the new balance is 0, we assume the account should be completely
  // emptied, so don't do any rounding.
  if (newBalanceAsNumber === 0) {
    amountToTransfer = -currentBalance;
  } else {
    amountToTransfer =
      Math.trunc(newBalanceAsNumber) - Math.trunc(currentBalance);
  }

  return amountToTransfer;
}

export function formatAmount(amount: number) {
  return formatters.formatAmount(amount, { currency: 'kr' });
}
